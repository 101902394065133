<template>
  <a :href="`/profile/${company.username}`" class="crd crd-company">
    <div class="companyLogo">
      <img :src="companyImage(company.companyImg)" :alt="company.username" />
    </div>
    <div class="crdDetails">
      <h3>{{ company.name }}</h3>
      <p>{{ retrunCompanyTypeName(company.companyType) }}</p>

      <div class="crdLocation">
        <span class="icon">
          <svg
            data-v-3f392606=""
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokewidth="{1.5}"
            stroke="currentColor"
            class="icon h-[15px] h-[15px]"
          >
            <path
              data-v-3f392606=""
              strokelinecap="round"
              strokelinejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            ></path>
            <path
              data-v-3f392606=""
              strokelinecap="round"
              strokelinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            ></path>
          </svg>
        </span>
        <div>
          <span>{{ company.country }}</span
          ><span v-if="company.state">
            {{ ", " + company.state }}
          </span>
        </div>
      </div>

      <div class="crdTags">
        <div
          class="crdTag"
          v-for="(ind, index) in company.industries"
          :key="index"
        >
          {{
            $store.state.locale.locale == "ar"
              ? "#" + ind.arabicName + " "
              : "#" + ind.name + " "
          }}
        </div>
      </div>

      <div class="crdMetas">
        <div class="crdMeta">
          <div class="crdMetaItemLabel">{{ $t("company.Monthly_Sales") }}</div>
          <div class="crdMetaItemValue">
            {{
              $store.state.locale.locale == "en"
                ? calcMonthlySales(company.avgMonthlySales)
                : arCalcMonthlySales(company.avgMonthlySales)
            }}
            <span>{{ $store.state.myCurrency.myCurrency.symbol }}</span>
          </div>
        </div>
        <div class="crdMeta">
          <div class="crdMetaItemLabel">{{ $t("company.EBITDA_Margin") }}</div>
          <div class="crdMetaItemValue">{{ company.ebitda }}%</div>
        </div>
      </div>
    </div>
  </a>
</template>
<script>
import { BLink } from "bootstrap-vue";
import millify from "millify";

export default {
  components: {
    BLink,
  },
  props: {
    company: {
      type: Object,
      default: {},
    },
  },
  methods: {
    companyImage(data) {
      const logo = data.find((x) => {
        return x.name === "company Image";
      });
      if (!logo) {
        return require("@/assets/images/index/company.png");
      }
      return logo.fileUrl;
    },
    retrunCompanyTypeName(id) {
      let companyType = "";
      switch (id) {
        case 1:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Sole Proprietorship"
              : "مؤسسة فردية / تاجر وحيد";
          break;
        case 2:
          companyType =
            localStorage.getItem("language") === "en"
              ? "General Partnership"
              : "شراكة عامة";
          break;
        case 3:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Partnership (LLP)"
              : "شراكة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 4:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Limited Liability Company (LLC)"
              : "شركة ذات مسؤولية محدودة(ش.ذ.م.م)";
          break;
        case 5:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Private Limited Company"
              : "شركة خاصة محدودة ";
          break;
        case 6:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Public Limited Company"
              : "شركه عالميه محدوده";
          break;
        case 7:
          companyType =
            localStorage.getItem("language") === "en"
              ? "S Corporation"
              : "S Corporation";
          break;
        case 8:
          companyType =
            localStorage.getItem("language") === "en"
              ? "C Corporation"
              : "C Corporation";
          break;
        case 9:
          companyType =
            localStorage.getItem("language") === "en" ? "Other" : "غير ذلك";
          break;
        default:
          companyType =
            localStorage.getItem("language") === "en"
              ? "Sole Proprietorship"
              : "مؤسسة فردية / تاجر وحيد";
      }
      return companyType;
    },
    calcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    arCalcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        units: ["", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ".",
        space: true,
      })} `;
    },
  },
};
</script>
